import React from "react";

// Components
import Error from "../components/Error";

function info() {
  return <Error />;
}

export default info;
